import { useState } from "react";

function Square({ value, onSquareClick }) {
  return (
    <button className="square" onClick={onSquareClick}>
      {value}
    </button>
  );
}

export default function Board() {
  const [xIsNext, setXIsNext] = useState(true);
  const [squares, setSquares] = useState(Array(9).fill(null));

  
      function handleClick(i) {
        if(squares[i]||calculateWinner(squares)){
          //aca verifico que ese casillero no hay aestado clickeado.- o no haya ya un ganador , en tal caso termina el juego
          return;
        }
        const nextSquares = squares.slice();//devuelve una porcion del array , en este caso sin indice cpia el array entero
        if (xIsNext){
        nextSquares[i] = "X";
        }else{
          nextSquares[i] = "O";
        }
        setSquares(nextSquares);
        setXIsNext(!xIsNext);
        //aca asigno a la var xIsNext el valor contrario al que ya tenia
       
  
      }


        let status;
          const winner = calculateWinner(squares);
        if (winner){
          status = 'El ganador es ' + winner;
          //evaluo si winner tiene un valor, si es asi lo muestro 
        }else{
          status = 'Le toca a ' + (xIsNext ? 'X' : 'O');//en la condicion evaluo si xIsNext tiene un valor , si lo tienen devuelvo X , si no lo tiene devuelvo O
        }

  return (
    <>
    <div className="status">{status}</div>
      <div className="board-row">
        <Square value={squares[0]} onSquareClick={() => handleClick(0)} />
        <Square value={squares[1]} onSquareClick={() => handleClick(1)} />
        <Square value={squares[2]} onSquareClick={() => handleClick(2)} />
      </div>
      <div className="board-row">
        <Square value={squares[3]} onSquareClick={() => handleClick(3)} />
        <Square value={squares[4]} onSquareClick={() => handleClick(4)} />
        <Square value={squares[5]} onSquareClick={() => handleClick(5)} />
      </div>
      <div className="board-row">
        <Square value={squares[6]} onSquareClick={() => handleClick(6)} />
        <Square value={squares[7]} onSquareClick={() => handleClick(7)} />
        <Square value={squares[8]} onSquareClick={() => handleClick(8)} />
      </div>
    </>
  );

}

function calculateWinner(squares){
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  
  for (let i = 0; i < lines.length; i++){
    const [a, b, c]=lines[i];
    
    if(squares[a] && squares[a] === squares[b] && squares[a] === squares[c]){
      return squares[a];
    }
 
  }
  return null;

}



//Why immutability is important
//Declaringa winer
//https://react.dev/learn/tutorial-tic-tac-toe#adding-time-travel
