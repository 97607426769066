const authorweb = "https://gigena.net";
const author = "Pablo Gigena";

export default function linktree() {
  return (
    <div className="footer">
      <a href={authorweb}>Dev by {author}</a> • 
      <a>Práctica de React - TaTeTi</a>
    </div>
  );
}
